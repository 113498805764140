import axios from "@/util/axios";
import {interestsCategories} from "@/util/data";

export default {
  namespaced: true,
  state () {
    return {
      uploadedMedia: [],
      interests: [...interestsCategories]
    }
  },
  mutations: {
    setUploadedMedia(state, payload) {
      state.uploadedMedia = payload;
    },
    setInterests(state, payload) {
      state.interests = payload;
    }
  },
  actions: {
    uploadMedia({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/twitter/upload/media', payload)
          .then(response => {
            const uploadedImageUrl = response.data.url;
            commit('setUploadedMedia', [...state.uploadedMedia, uploadedImageUrl]);
            resolve(response);
          }).catch(() => {
            reject();
        });
      });
    },
  },
  getters: {
    getUploadedMedia: (state) => state.uploadedMedia,
  }
}